import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import FullPageSection from '../components/FullPageSection'
import HeroTitle from '../components/HeroTitle'
import Footer from '../components/FooterSpanish'
import Header from '../components/HeaderSpanish'

import bgImage from '../img/TC3.jpeg'
import tlcImage from '../img/about.png'

const AboutPage = (props) => (
  <main>
    <Header className="bg-white" />

    <FullPageSection
      className="overlay-blue align-items-center d-flex splash"
      bgImage={bgImage}
    >
      <Container>
        <Row>
          <Col xs={12}>
            <HeroTitle
              className="mt-5 pt-5"
              textArray={[
                'Tiffany L. Cabán es una defensora',
                'pública que quiere representar al',
                'Distrito 22 en el Consejo Municipal de NYC',
                'Vota • Martes 22 de junio',
              ]}
            />
          </Col>
        </Row>
      </Container>
    </FullPageSection>
    <div className="mt-3 pt-5 pb-5 mb-5">
      <Container>
        <Row>
          <Col md={6} className="position-relative">
            <img src={tlcImage} className="w-100 mb-4" height="auto" />
          </Col>
          <Col md={6}>
            <section>
              <h2 className="mb-4">Conoce a Tiffany</h2>
              <div className="font-1">
                <p>
                  Tiffany Cabán es una latina queer de 33 años que se ha
                  postulado en la contienda para el Consejo Ciudadano porque
                  ella quiere que Nueva York logre alcanzar su potencial
                  progresista. Tiffany sabe que el gobierno de la ciudad puede
                  trabajar para darle apoyo a las personas trabajadoras, y ella
                  no dejará de luchar hasta que esto suceda.
                </p>
                <p>
                  Oriunda de Queens, Tiffany nació en Richmond Hill de padres
                  puertorriqueños que crecieron en Woodside Houses. Su padre
                  trabajó como mecánico de elevadores y su madre cuidaba de los
                  niños de otras familias. Tiffany asistió a las escuelas PS 62
                  en Richmond Hill y JHS 210 Elizabeth Blackwell en Woodhaven, y
                  después asistió a la Preparatoria St. Francis en Fresh
                  Meadows.
                </p>
                <p>
                  La contienda revolucionaria de Tiffany por la Fiscalía del
                  Distrito de Queens en el 2019 cambió radicalmente la
                  conversación en torno a la reforma del sistema de justicia
                  penitenciario, tanto en Nueva York como a lo largo y ancho de
                  Estados Unidos. Ella se enfrentó a la maquinaria política y
                  retó a las posturas convencionales, ayudando a impulsar el
                  movimiento creciente de fiscales que pugnan por el
                  desencarcelamiento por todo el país.
                </p>
              </div>
            </section>
          </Col>
        </Row>
      </Container>
      <Container className="mt-3">
        <Row>
          <Col xs={12}>
            <section>
              <div className="font-1">
                <p>
                  Después de llegar muy cerca a la victoria en esa contienda,
                  Tiffany se unió al Partido de las Familias Trabajadoras como
                  organizadora política nacional para ayudar a la elección de
                  candidatos a fiscales con una visión progresista y en defensa
                  del desencarcelamiento.
                </p>
                <p>
                  Antes de dedicarse a la política, Tiffany trabajó como
                  defensora pública, representando a personas que no tenían
                  recursos para defenderse en contra del brutal sistema de
                  encarcelamiento masivo. Los años que ella pasó atestiguando
                  los abusos de este sistema la inspiró a postularse como Fiscal
                  de Distrito, con la firme determinación de redefinir
                  radicalmente cómo abordamos tanto la salud como la seguridad
                  pública.
                </p>
                <p>
                  Ahora está también llevando esta misma mentalidad hacia el
                  Consejo Ciudadano, en su contienda para representar al
                  Distrito 22, el cual incluye Astoria, Rikers Island, y partes
                  de Jackson Heights, Woodside, así como East Elmhurst.
                </p>
                <p>
                  Tiffany obtuvo su licenciatura en Criminología, Leyes, y
                  Justicia en la Universidad de Penn State, y su título de
                  abogada en la Escuela de Leyes de Nueva York. Debido a su gran
                  pasión por la relación que existe entre la ley, la política, y
                  la desigualdad social, ella entró a la escuela de leyes
                  sabiendo que algún día llegaría a ser defensora pública.
                </p>
                <p>
                  Durante sus años en los Servicios de Defensa del Condado de
                  Nueva York (NYCDS, por sus siglas en inglés) y en la Práctica
                  de Defensa Penal de la Sociedad de Asistencia Legal, Tiffany
                  representó a más de mil clientes indigentes en casos desde
                  esquivamiento de torniquetes hasta homicidios. Durante su
                  carrera profesional, ella ha utilizado a la ley para ayudar a
                  las comunidades más vulnerables de la Ciudad de Nueva York, y
                  estas experiencias abogando por sus clientes le ayudaron a
                  identificar varias de las peores desigualdades dentro de
                  nuestro sistema de justicia penal — un sistema que criminaliza
                  a la pobreza, a los trastornos de salud mental, y los
                  desórdenes de abuso de sustancias.
                </p>
                <p>
                  En su acontecer diario como defensora, Tiffany utilizó un
                  enfoque global para trabajar con sus clientes y construir
                  estabilidad en sus vidas. Este enfoque está arraigado en sus
                  propias experiencias personales de violencia, salud mental,
                  desórdenes de abuso de sustancias, e inseguridad económica.
                  Éste es el enfoque ella llevará consigo al Consejo Municipal.
                </p>
                <p>
                  Tiffany vive en Astoria y es la orgullosa madre de dos perros
                  rescatados, Natalie y Coltrane. Ella ha sido atleta desde la
                  preparatoria y participa en ligas deportivas de la comunidad
                  LGBTQIA+, y es una fanática acérrima de los deportes.
                </p>
              </div>
            </section>
          </Col>
        </Row>
      </Container>
    </div>
    <Footer className="mt-5" />
  </main>
)

export default AboutPage
